import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from "@angular/forms";
import { ValidationService, Validators as V } from "../../shared/services/validation.service";
import { catchError, throwError } from "rxjs";
import { NotificationService } from "../../shared/services/notification.service";
import { Session } from "../../shared/models/session.model";
import { SessionService } from "../../shared/services/sessions.service";

@Component({
  selector: 'app-session-form',
  templateUrl: './session-form.component.html',
    standalone: false
})
export class SessionFormComponent implements OnInit {

    @Input() model: Session;
    @Output() onAfterSave = new EventEmitter<Session>();
    @Output() onCancel = new EventEmitter<void>();

    form: FormGroup;
    loading = false;

    tonItems: {id: number, text: number}[] = [
        {id: 0, text: 0},
        {id: 1, text: 1},
        {id: 5, text: 5},
    ];
    npiItems: {id: number, text: number}[] = [
        {id: 0, text: 0},
        {id: 1, text: 1},
    ];

    constructor(
        private formBuilder: FormBuilder,
        public validationService: ValidationService,
        private sessionService: SessionService,
        private notifications: NotificationService
    ) {

    }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            systemType: [this.model.systemType, V.compose([V.maxLength(255)])],
            serviceType: [this.model.serviceType, V.compose([V.maxLength(255)])],
            hostIp: [this.model.hostIp, V.compose([V.required, V.domainOrIp(true), V.maxLength(255), V.noSpace()])],
            hostPort: [this.model.hostPort, V.compose([V.required, V.digits(true), V.noSpace(), V.min(0), V.max(65535)])],
            systemId: [this.model.systemId, V.compose([V.required, V.maxLength(16), V.gsm7(true), V.noSpace()])],
            password: ['', V.compose([V.maxLength(9), V.gsm7(true), V.noSpace()])],
            enabled: [this.model.enabled, V.compose([V.required])],
            useSsl: [this.model.useSsl],
            throughput: [this.model.throughput, V.compose([V.required, V.min(1), V.max(50)])],
            dstTon: [this.model.dstTon, V.compose([V.required])],
            dstNpi: [this.model.dstNpi, V.compose([V.required])],
            windowSize: [this.model.windowSize, V.compose([V.min(1, true), V.max(20, true)])],
            windowWaitTimeout: [this.model.windowWaitTimeout, V.compose([V.min(10, true), V.max(300, true)])],
        });
        if (!this.model.id) {
            this.form.get('password').addValidators(V.required);
        }
    }

    onSave() {
        this.loading = true;
        const value = this.form.value as Session;
        const model = Object.assign(this.model, value);
        this.sessionService.save(model).pipe(
            catchError(e => {
                this.loading = false;
                return throwError(() => e);
            })
        ).subscribe(org => {
            this.loading = false;
            this.notifications.success('Session ' + (this.model.id ? 'updated' : 'created'), 'Sessions');
            this.onAfterSave.emit(value);
        });
    }

}
