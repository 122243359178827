import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { NzIconDirective } from 'ng-zorro-antd/icon';
import { NotificationFill } from '@ant-design/icons-angular/icons';
import { JsonPipe, NgIf } from '@angular/common';
import { ServerResponseError, ServerResponseException } from '../../models/utils';
import { NzCollapseComponent, NzCollapseModule } from 'ng-zorro-antd/collapse';

@Component({
  selector: 'app-response-error-notification',
  imports: [
    NzIconDirective,
    NzCollapseModule,
    JsonPipe
  ],
  templateUrl: './response-error-notification.component.html',
  styleUrl: './response-error-notification.component.less'
})
export class ResponseErrorNotificationComponent {
  @ViewChild("template", {static: true}) template!: TemplateRef<any>;
  @Input() status: string = '';
  @Input() message = '';
  @Input() serverError: ServerResponseError | null = null;
  @Input() serverException: ServerResponseException | null = null;
}
