const httpStatuses  = new Map<number, string>();
httpStatuses.set(100, 'Continue');
httpStatuses.set(101, 'Switching Protocols');
httpStatuses.set(102, 'Processing');
httpStatuses.set(103, 'Early Hints');
httpStatuses.set(200, 'OK');
httpStatuses.set(201, 'Created');
httpStatuses.set(202, 'Accepted');
httpStatuses.set(203, 'Non-Authoritative Information');
httpStatuses.set(204, 'No Content');
httpStatuses.set(205, 'Reset Content');
httpStatuses.set(206, 'Partial Content');
httpStatuses.set(207, 'Multi-Status');
httpStatuses.set(208, 'Already Reported');
httpStatuses.set(226, 'IM Used');
httpStatuses.set(300, 'Multiple Choices');
httpStatuses.set(301, 'Moved Permanently');
httpStatuses.set(302, 'Found');
httpStatuses.set(303, 'See Other');
httpStatuses.set(304, 'Not Modified');
httpStatuses.set(305, 'Use Proxy');
httpStatuses.set(307, 'Temporary Redirect');
httpStatuses.set(308, 'Permanent Redirect');
httpStatuses.set(400, 'Bad Request');
httpStatuses.set(401, 'Unauthorized');
httpStatuses.set(402, 'Payment Required');
httpStatuses.set(403, 'Forbidden');
httpStatuses.set(404, 'Not Found');
httpStatuses.set(405, 'Method Not Allowed');
httpStatuses.set(406, 'Not Acceptable');
httpStatuses.set(407, 'Proxy Authentication Required');
httpStatuses.set(408, 'Request Timeout');
httpStatuses.set(409, 'Conflict');
httpStatuses.set(410, 'Gone');
httpStatuses.set(411, 'Length Required');
httpStatuses.set(412, 'Precondition Failed');
httpStatuses.set(413, 'Payload Too Large');
httpStatuses.set(414, 'URI Too Long');
httpStatuses.set(415, 'Unsupported Media Type');
httpStatuses.set(416, 'Range Not Satisfiable');
httpStatuses.set(417, 'Expectation Failed');
httpStatuses.set(421, 'Misdirected Request');
httpStatuses.set(422, 'Unprocessable Entity');
httpStatuses.set(423, 'Locked');
httpStatuses.set(424, 'Failed Dependency');
httpStatuses.set(425, 'Too Early');
httpStatuses.set(426, 'Upgrade Required');
httpStatuses.set(428, 'Precondition Required');
httpStatuses.set(429, 'Too Many Requests');
httpStatuses.set(431, 'Request Header Fields Too Large');
httpStatuses.set(451, 'Unavailable For Legal Reasons');
httpStatuses.set(500, 'Internal Server Error');
httpStatuses.set(501, 'Not Implemented');
httpStatuses.set(502, 'Bad Gateway');
httpStatuses.set(503, 'Service Unavailable');
httpStatuses.set(504, 'Gateway Timeout');
httpStatuses.set(505, 'HTTP Version Not Supported');
httpStatuses.set(506, 'Variant Also Negotiates');
httpStatuses.set(507, 'Insufficient Storage');
httpStatuses.set(508, 'Loop Detected');
httpStatuses.set(510, 'Not Extended');
httpStatuses.set(511, 'Network Authentication Required');

export { httpStatuses };
