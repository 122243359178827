import { ComponentRef, Inject, Injectable, Injector, TemplateRef, ViewContainerRef } from '@angular/core';
import { ServerResponseError, ServerResponseException } from '../models/utils';
import {
  ResponseErrorNotificationComponent
} from '../components/response-error-notification/response-error-notification.component';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { DOCUMENT } from '@angular/common';
import { Subject } from 'rxjs';


@Injectable({ providedIn: "root" })
export class AppService {

  private containerRef!: ViewContainerRef;

  private waitNotifications: ResponseErrorNotification[] = [];

  titleTemplate = new Subject<TemplateRef<unknown>|null>();

  constructor(
    private injector: Injector,
    private notification: NzNotificationService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  showRecaptchaBadge() {
    if (this.document.body.classList.contains('recaptcha-badge-hide')) {
      document.body.classList.remove('recaptcha-badge-hide');
    }
  }

  hideRecaptchaBadge() {
    if (!this.document.body.classList.contains('recaptcha-badge-hide')) {
      document.body.classList.add('recaptcha-badge-hide');
    }
  }

  setContainer(viewContainerRef: ViewContainerRef) {
    this.containerRef = viewContainerRef;

    if (this.waitNotifications.length) {
      for (const n of this.waitNotifications) {
        this.responseErrorNotification(n);
      }
      this.waitNotifications = [];
    }
  }

  createComponent<T>(component: { new (...args: any[]): T }, inputs: Partial<T>): ComponentRef<T> | null {
    if (!this.containerRef) {
      console.error('No container set for dynamic component');
      return null;
    }
    this.containerRef.clear();
    const componentRef = this.containerRef.createComponent(component, { injector: this.injector });

    for (const key in inputs) {
      componentRef.setInput(key, inputs[key]!);
    }

    return componentRef;
  }

  responseErrorNotification(n: ResponseErrorNotification) {
    if (!this.containerRef) {
      this.waitNotifications.push(n);
      return;
    }
    let exception: ServerResponseException|null = (n.serverError && 'message' in n.serverError) ? n.serverError : null;
    let error: ServerResponseError|null = (n.serverError && 'error' in n.serverError) ? n.serverError : null;
    if (exception) {
      exception.stackTrace = [];
      n.message = exception.message;
    }
    const component = this.createComponent(ResponseErrorNotificationComponent, {
      status: n.status,
      message: n.message,
      serverError: error,
      serverException: exception,
    });
    const notification = this.notification.template(component!.instance.template, {
      nzClass: 'response-error-notification',
    });
    notification.onClose.subscribe(() => {
      component!.destroy();
    });
  }
}

export interface ResponseErrorNotification {
  status: string;
  message: string;
  serverError: ServerResponseError|ServerResponseException|null;
}
