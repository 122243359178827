<div class="flex items-center justify-center mt-30 ml-auto mr-auto">
    <div class="w-full sm:w-auto py-8 px-4 sm:p-12 sm:rounded-2xl sm:shadow sm:bg-card">
        <div class="w-full max-w-80 sm:w-80 mx-auto sm:mx-0">
            <div class="w-12 m-auto">
                <img src="assets/images/logo/logo.svg">
            </div>
            <div class="mt-8 text-4xl font-extrabold tracking-tight leading-tight text-center">Confirmation required</div>
            <div class="mt-4 text-center">
                The administrator has not yet approved your account
            </div>
        </div>
    </div>
</div>