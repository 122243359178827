<ng-template #template>
  <div class="ant-notification-notice-content">
    <div class="ant-notification-notice-with-icon">
          <span class="ant-notification-notice-icon">
            <nz-icon nzType="close-circle" nzTheme="fill" />
          </span>
      @if (status) {
        <div class="ant-notification-notice-message">{{ status }}</div>
      }
      <div class="ant-notification-notice-description">
        @if (message) {
          {{ message }}
        }
        @if (serverError) {
          <nz-collapse nzGhost>
            <nz-collapse-panel nzHeader="Details">
              <code><pre>{{ serverError | json }}</pre></code>
            </nz-collapse-panel>
          </nz-collapse>
        }
        @if (serverException) {
          <nz-collapse nzGhost>
            <nz-collapse-panel nzHeader="Details">
              <code><pre>{{ serverException | json }}</pre></code>
            </nz-collapse-panel>
          </nz-collapse>
        }
      </div>
    </div>
  </div>
</ng-template>
