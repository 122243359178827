import { AfterViewInit, Component, HostListener, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { RouteConfigLoadEnd, RouteConfigLoadStart, Router, RouterLink, RouterOutlet } from '@angular/router';
import { BrowserUtils } from "../shared/services/browser-utils";
import { UserService } from "../shared/services/user.service";
import { CurrentUser, Role } from "../shared/models/user.model";
import { NgClass } from "@angular/common";
import { ModalsWidgetComponent } from "../shared/components/modals-widget/modals-widget.component";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzLayoutModule } from "ng-zorro-antd/layout";
import { NzMenuModule } from "ng-zorro-antd/menu";
import { NzPopoverModule } from "ng-zorro-antd/popover";
import { NzSpinModule } from "ng-zorro-antd/spin";
import ls from "localstorage-slim";
import { AppService } from "../shared/services/app.service";

@Component({
    selector: 'app-component',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
    imports: [
        RouterOutlet,
        NgClass,
        ModalsWidgetComponent,
        NzIconModule,
        NzLayoutModule,
        NzMenuModule,
        NzToolTipModule,
        NzPopoverModule,
        NzSpinModule,
        NgClass,
        RouterLink
    ],
    standalone: true
})

export class AppComponent implements OnInit, AfterViewInit {

    @ViewChild('dynamicContainer', { read: ViewContainerRef, static: true }) dynamicContainer!: ViewContainerRef;

    isAuth: boolean = false;
    user: CurrentUser;

    loading = false;
    isCollapsed = false;
    loadingPath: string|undefined = undefined;

    isClient = false;
    isAdmin = false;

    constructor(
        private router: Router,
        private userService: UserService,
        private vc: ViewContainerRef,
        private appService: AppService
    ) {
        userService.isAuth.subscribe(_ => this.isAuth = _);
        userService.user.subscribe(user => {
            if (user) {
                this.isClient = user.roles.includes(Role.USER);
                this.isAdmin = user.roles.includes(Role.ADMIN);
                this.user = user;
            } else {
                this.isClient = false;
                this.isAdmin = false;
                this.user = null;
            }
        });
        router.events.subscribe(event => {
            if (event instanceof RouteConfigLoadStart) {
                this.loadingPath = event.route.path;
            }
            if (event instanceof RouteConfigLoadEnd) {
                this.loadingPath = undefined;
            }
        });
    }

    onChangeCollapsed(collapsed: boolean) {
        this.isCollapsed = collapsed;
        ls.set(`main-menu-collapsed`, collapsed);
    }

    ngOnInit() {

    }

    ngAfterViewInit() {
        this.fixCollapsed();
        this.appService.setContainer(this.dynamicContainer);
    }

    @HostListener('window:beforeunload', ['$event'])
    unloadHandler(event: Event) {
        BrowserUtils.isUnload = true;
    }

    logout() {
        this.userService.toLogout();
    }

    private fixCollapsed() {
        setTimeout(() => {
            this.isCollapsed = ls.get(`main-menu-collapsed`) || false;
        }, 0);
    }

}
