import {
    ApplicationConfig,
    provideZoneChangeDetection,
    importProvidersFrom,
    provideAppInitializer, inject, DEFAULT_CURRENCY_CODE
} from '@angular/core';
import { provideRouter, Router } from '@angular/router';
import { en_US, provideNzI18n } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { FormsModule } from '@angular/forms';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { UserService } from './shared/services/user.service';
import { environment } from '../environments/environment';
import { AllEnterpriseModule, LicenseManager, ModuleRegistry } from 'ag-grid-enterprise';
import ls from 'localstorage-slim';
import { routes } from "./routes";
import { HttpErrorInterceptor } from "./shared/services/http-error-interceptor.service";
import { HttpUrlInterceptor } from "./shared/services/http-url.interceptor";
import { AuthGuard } from "./shared/auth-guard";

registerLocaleData(en);

ls.config.encrypt = false;

export function initApp() {
    const userService = inject(UserService);
    return new Promise((resolve, reject) => {
        userService.init().then(() => {
            resolve(true);
        }).catch(e => {
            resolve(true);
        });
    });
}

if (environment.agGridLicenseKey) {
    LicenseManager.setLicenseKey(environment.agGridLicenseKey);
}

ModuleRegistry.registerModules([AllEnterpriseModule]);

export const appConfig: ApplicationConfig = {
    providers: [
        { provide: "API_URL", useValue: environment.apiUrl },
        { provide: "FRONTEND_URL", useValue: environment.frontendUrl },
        { provide: "KEYCLOAK_URL", useValue: environment.keycloakUrl },
        { provide: "LOCAL_STORAGE_PREFIX", useValue: environment.storagePrefix },
        { provide: "STATUSPAGE_ID", useValue: environment.statusPageId },
        { provide: "RELEASE_VERSION", useValue: environment.releaseVersion },
        provideZoneChangeDetection({ eventCoalescing: true }),
        provideRouter(routes),
        provideNzI18n(en_US),
        importProvidersFrom(FormsModule),
        provideAnimationsAsync(),
        provideAppInitializer(initApp),
        provideHttpClient(withInterceptorsFromDi()),
        { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: HttpUrlInterceptor, multi: true },
        AuthGuard,
        { provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR' }
    ]
};