<nz-layout class="app-layout" [ngClass]="{'full-layout': isCollapsed, 'auth-layout': !isAuth, 'user-layout': isAuth}">
    @if (isAuth) {
        <nz-sider class="menu-sidebar"
                  nzCollapsible
                  nzWidth="256px"
                  nzBreakpoint="md"
                  [(nzCollapsed)]="isCollapsed"
                  [nzTrigger]="null"
        >
            <div class="sidebar-content">
                <div class="sidebar-logo">
                    <span class="icon"><nz-icon nzType="check-circle" nzTheme="twotone"/></span> <span class="text">Messaging platform</span>
                </div>
                <div class="sidebar-menu-items">
                    <ul nz-menu nzTheme="dark" nzMode="inline" [nzInlineCollapsed]="isCollapsed">
                    <li nz-menu-item nzMatchRouter nz-tooltip nzTooltipPlacement="right" [nzTooltipTitle]="isCollapsed ? 'Sms' : ''">
                        <a routerLink="/sms">
                            <nz-icon [nzType]="loadingPath == 'sms' ? 'loading' : 'dashboard'"/>
                            <span>Sms</span>
                        </a>
                    </li>
                    @if (isClient) {
                        <li nz-menu-item nzMatchRouter nzMatchRouterExact nz-tooltip nzTooltipPlacement="right" [nzTooltipTitle]="isCollapsed ? 'Products' : ''">
                            <a routerLink="/products">
                                <nz-icon [nzType]="loadingPath == 'products' ? 'loading' : 'dashboard'"/>
                                <span>Products</span>
                            </a>
                        </li>
                    }
                    @if (isClient) {
                        <li nz-menu-item nzMatchRouter nzMatchRouterExact nz-tooltip nzTooltipPlacement="right" [nzTooltipTitle]="isCollapsed ? 'Coverage' : ''">
                            <a routerLink="/products/coverage">
                                <nz-icon [nzType]="loadingPath == 'products/coverage' ? 'loading' : 'dashboard'"/>
                                <span>Coverage</span>
                            </a>
                        </li>
                    }
                    @if (isClient) {
                        <li nz-menu-item nzMatchRouter nz-tooltip nzTooltipPlacement="right" [nzTooltipTitle]="isCollapsed ? 'Routing' : ''">
                            <a routerLink="/routing">
                                <nz-icon [nzType]="loadingPath == 'routing' ? 'loading' : 'dashboard'"/>
                                <span>Routing</span>
                            </a>
                        </li>
                    }
                    @if (isClient) {
                        <li nz-submenu nzTitle="MNP" nzIcon="dashboard">
                            <ul>
                                <li nz-menu-item nzMatchRouter>
                                    <a routerLink="/mnp/routes">Routes</a>
                                </li>
                                <li nz-menu-item nzMatchRouter>
                                    <a routerLink="/mnp/coverage">Coverage</a>
                                </li>
                            </ul>
                        </li>
                    }
                    <li nz-menu-item nzMatchRouter nz-tooltip nzTooltipPlacement="right" [nzTooltipTitle]="isCollapsed ? 'Networks' : ''">
                        <a routerLink="/networks">
                            <nz-icon [nzType]="loadingPath == 'networks' ? 'loading' : 'dashboard'"/>
                            <span>Networks</span>
                        </a>
                    </li>
                    <li nz-menu-item nzMatchRouter nz-tooltip nzTooltipPlacement="right" [nzTooltipTitle]="isCollapsed ? 'Invoice' : ''">
                        <a routerLink="/invoice">
                            <nz-icon [nzType]="loadingPath == 'invoice' ? 'loading' : 'dashboard'"/>
                            <span>Invoice</span>
                        </a>
                    </li>
                    <li nz-menu-item nzMatchRouter nz-tooltip nzTooltipPlacement="right" [nzTooltipTitle]="isCollapsed ? 'Invoice' : ''">
                        <a routerLink="/plan">
                            <nz-icon [nzType]="loadingPath == 'plan' ? 'loading' : 'dashboard'"/>
                            <span>Plan</span>
                        </a>
                    </li>
                    @if (isAdmin) {
                        <li nz-menu-item nzMatchRouter nz-tooltip nzTooltipPlacement="right" [nzTooltipTitle]="isCollapsed ? 'Users' : ''">
                            <a routerLink="/pending-users">
                                <nz-icon [nzType]="loadingPath == 'pending-users' ? 'loading' : 'dashboard'"/>
                                <span>Users</span>
                            </a>
                        </li>
                    }
                    @if (isClient) {
                        <li nz-menu-item nzMatchRouter nz-tooltip nzTooltipPlacement="right" [nzTooltipTitle]="isCollapsed ? 'API' : ''">
                            <a routerLink="/api/client">
                                <nz-icon [nzType]="loadingPath == 'api/client' ? 'loading' : 'dashboard'"/>
                                <span>API</span>
                            </a>
                        </li>
                    }
                </ul>
                </div>
                @if (user) {
                    <ul class="sidebar-footer" [ngClass]="isCollapsed ? 'ant-menu-inline-collapsed' : ''" nz-menu nzTheme="dark">
                        <li nz-submenu [nzTitle]="user.email" nzIcon="user">
                            <ul>
                                <li nz-menu-item (click)="logout()"><nz-icon nzType="logout" nzTheme="outline" />Logout</li>
                            </ul>
                        </li>
                    </ul>
                }
            </div>
        </nz-sider>
    }
    <nz-layout class="app-content">
        <nz-content [ngClass]="isAuth ? 'main-layout' : 'auth-layout'">
            <div class="inner-content">
                <router-outlet></router-outlet>
            </div>
        </nz-content>
    </nz-layout>
</nz-layout>
<ng-container #dynamicContainer></ng-container>
<app-modals-widget></app-modals-widget>