import { Component } from '@angular/core';

@Component({
    selector: 'app-error-404',
    templateUrl: './error-404.component.html',
    standalone: false
})

export class Error404Component {

}