<form [formGroup]="form" class="pos-relative form">
    <div [hidden]="!loading" class="spinner"><div class="sk-spinner sk-spinner-pulse"></div></div>
    <div class="form-row d-flex gap-15 my-15">
        <div class="form-group w-100">
            <label class="form-label required">Login</label>
            <input formControlName="systemId" type="text" class="form-control" />
            <input-errors [control]="form.controls.systemId" [service]="validationService"></input-errors>
        </div>
        <div class="form-group w-100">
            <label class="form-label" [ngClass]="{required: !model.id}">Password</label>
            <input formControlName="password" type="password" class="form-control" />
            <input-errors [control]="form.controls.password" [service]="validationService"></input-errors>
        </div>
        <div class="form-group w-100">
            <label class="form-label required">Host</label>
            <input formControlName="hostIp" type="text" class="form-control" />
            <input-errors [control]="form.controls.hostIp" [service]="validationService"></input-errors>
        </div>
        <div class="form-group w-100">
            <label class="form-label required">Port</label>
            <input formControlName="hostPort" type="text" class="form-control" />
            <input-errors [control]="form.controls.hostPort" [service]="validationService"></input-errors>
        </div>
    </div>
    <div class="form-row d-flex gap-15 my-15">
        <div class="form-group w-100">
            <label class="form-label">System type</label>
            <input formControlName="systemType" type="text" class="form-control" />
            <input-errors [control]="form.controls.systemType" [service]="validationService"></input-errors>
        </div>
        <div class="form-group w-100">
            <label class="form-label">Service type</label>
            <input formControlName="serviceType" type="text" class="form-control" />
            <input-errors [control]="form.controls.serviceType" [service]="validationService"></input-errors>
        </div>
    </div>
    <div class="form-row d-flex gap-15 my-15">
        <div class="form-group w-100">
            <label class="form-label required">Throughput</label>
            <input formControlName="throughput" type="text" class="form-control" />
            <input-errors [control]="form.controls.throughput" [service]="validationService"></input-errors>
        </div>
        <div class="form-group w-100">
            <label class="form-label">Window size</label>
            <input formControlName="windowSize" type="text" class="form-control" />
            <input-errors [control]="form.controls.windowSize" [service]="validationService"></input-errors>
        </div>
        <div class="form-group w-100">
            <label class="form-label">Window Wait Timeout</label>
            <input formControlName="windowWaitTimeout" type="text" class="form-control" />
            <input-errors [control]="form.controls.windowWaitTimeout" [service]="validationService"></input-errors>
        </div>
    </div>
    <div class="form-row d-flex gap-15 my-15">
        <div class="form-group w-100">
            <label class="form-label required">Destination TON</label>
            <select formControlName="dstTon" class="form-control form-select">
                <option *ngFor="let i of tonItems" [value]="i.id">{{ i.text }}</option>
            </select>
            <input-errors [control]="form.controls.dstTon" [service]="validationService"></input-errors>
        </div>
        <div class="form-group w-100">
            <label class="form-label required">Destination NPI</label>
            <select formControlName="dstNpi" class="form-control form-select">
                <option *ngFor="let i of npiItems" [value]="i.id">{{ i.text }}</option>
            </select>
            <input-errors [control]="form.controls.dstNpi" [service]="validationService"></input-errors>
        </div>
    </div>
    <div class="form-row d-flex gap-15 my-15">
        <div class="form-group w-100">
            <div class="form-check form-switch">
                <input type="checkbox" class="form-check-input switch-medium"  formControlName="enabled">
                <label class="form-check-label">Enabled</label>
            </div>
            <input-errors [control]="form.controls.enabled" [service]="validationService"></input-errors>
        </div>
        <div class="form-group w-100">
            <div class="form-check form-switch">
                <input type="checkbox" class="form-check-input switch-medium"  formControlName="useSsl">
                <label class="form-check-label">TLS Enabled (SSL)</label>
            </div>
            <input-errors [control]="form.controls.useSsl" [service]="validationService"></input-errors>
        </div>
    </div>
    <div class="pb-15 d-flex justify-content-between align-items-center">
        <button role="button" class="btn btn-outline-dark" (click)="onCancel.emit()">Cancel</button>
        <button class="btn btn-primary" type="submit" (click)="onSave()" [disabled]="!form.valid">Apply</button>
    </div>
</form>

