<div class="page-content api-page-content">
    <div [hidden]="!loading" class="spinner"><div class="sk-spinner sk-spinner-pulse"></div></div>
    <div class="api-wrapper">
        <h4>Get more insights</h4>
        <p class="insight-note">
            Here you can explore the Developer Documentation that includes the API
            details.
        </p>
        <div class="insight-button-holder d-flex">
            <a href="#" target="_blank" class="btn btn-primary px-20">Developer documentation <span class="icon-external"></span></a>
        </div>
    </div>
    <div *ngIf="!myApiKey" class="mt-15 text-center">
        <button class="btn btn-primary" (click)="onClickActivate()">Activate API</button>
    </div>
    <ng-container *ngIf="myApiKey">
        <div class="api-wrapper">
            <h4 class="api-heading">Primary key</h4>
            <div class="detail-box-wrap">
                <div class="api-detail-holder">
                    <div class="detail-box">
                        <h5 class="detail-title">Access ID</h5>
                        <div class="api-box-holder">
                            <div class="api-box">{{ myApiKey.accessId }}</div>
                            <div class="copy-btn" (click)="onClickCopy(myApiKey.accessId)">
                                <span class="icon icon-copy"></span>
                                <span class="text">Copy</span>
                            </div>
                        </div>
                    </div>
                    <div class="detail-box">
                        <h5 class="detail-title">Date of entry</h5>
                        <div class="api-box-holder">
                            <div class="api-box">{{ (myApiKey.updatedAt ? myApiKey.updatedAt : myApiKey.createdAt) | date: 'dd/MM/yy hh:mm:ss' }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="api-wrapper">
            <h4 class="api-heading" style="margin-bottom: 0">
                Secondary key
                <button class="btn btn-primary btn-sm" (click)="onClickSecondaryGenerate()" *ngIf="!myApiKey.hasSecondaryAccessKey">Generate</button>
                <button class="btn btn-primary btn-sm" (click)="onClickMakePrimary()" *ngIf="myApiKey.hasSecondaryAccessKey">Make primary</button>
            </h4>
        </div>
        <div class="mt-15 text-center">
            <button class="btn btn-danger" (click)="onClickDeactivate()">Deactivate API</button>
        </div>
    </ng-container>
</div>

<ng-template #copyKeyModalTpl let-dialogRefTemplate="dialogRef">
    <div class="modal-header mb-10">
        <div class="modal-title">
            <h3 class="d-inline-block">Access key</h3>
        </div>
    </div>
    <div class="modal-body">
        The following is API key that was generated for your account.
        <br>
        <strong>
            This is the only time you will be able to see this key so make
            sure you store it safely on your computer.
        </strong>
        <div class="detail-box-wrap">
            <div class="api-detail-holder">
                <div class="detail-box">
                    <h5 class="detail-title">Access key</h5>
                    <div class="api-box-holder">
                        <div class="api-box">{{ copyKey }}</div>
                        <div class="copy-btn" (click)="onClickCopy(copyKey)">
                            <span class="icon icon-copy"></span>
                            <span class="text">Copy</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="api-detail-holder">
                <div class="detail-box d-flex align-items-center pointer">
                    <div class="input-check">
                        <input #confirmCheckBox type="checkbox" class="form-check-input">
                    </div>
                    <div style="margin-left: 5px" (click)="confirmCheckBox.click()">
                        I confirm access key has been safely stored.
                    </div>
                </div>
            </div>
        </div>
        <div class="button-holder d-flex justify-content-center" style="padding-bottom: 1rem">
            <button class="btn btn-primary ms-15" [disabled]="!confirmCheckBox.checked" (click)="dialogRefTemplate.close(true)">Close</button>
        </div>
    </div>
</ng-template>