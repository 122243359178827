import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { AGTableBase } from "../../shared/components/table/ag-table-base";
import { AgGridUtils } from "../../shared/services/ag-grid-utils";
import {
    CellClickedEvent,
    ColumnMovedEvent, ColumnResizedEvent,
    ColumnState, ColumnVisibleEvent, GetMainMenuItemsParams,
    GridApi, GridOptions, ICellRendererParams, SortChangedEvent
} from "ag-grid-community";
import { UserService } from "../../shared/services/user.service";
import { LocalStorage } from "../../shared/services/local-storage.service";
import { DatePipe } from "@angular/common";
import { catchError, Subscription, throwError } from "rxjs";
import { SessionsTableActionsRendererComponent } from "./sessions-table-actions-renderer.component";
import { debounceTime } from "rxjs/operators";
import { DialogRef, ModalService } from "../../shared/services/modal.service";
import { NotificationService } from "../../shared/services/notification.service";
import { Session, SessionAllRequestParams } from "../../shared/models/session.model";
import { SessionService } from "../../shared/services/sessions.service";

@Component({
    selector: 'app-sessions-table',
    templateUrl: './sessions-table.component.html',
    styleUrls: [],
    standalone: false
})
export class SessionsTableComponent extends AGTableBase implements OnInit, OnDestroy {
    theme = AgGridUtils.theme;
    declare gridApi: GridApi<Session>;

    gridOptions: GridOptions<Session> = {
        onGridReady: e => {
            const userId = this.userService.user.value.id;
            this.gridApi = e.api;
            const columnState = this.storage.getItem<ColumnState[]>(`sessions-table-columns-${userId}`) || [];
            if (columnState && columnState.length) {
                this.gridApi.applyColumnState({ state: columnState });
            }
            //this.addHorizontalScrollOnTop();
            this.update();
        },
        onSortChanged: (e: SortChangedEvent) => this.columnChange$.next(e),
        onColumnResized: (event: ColumnResizedEvent<Session>) => this.onColumnResized(event),
        onColumnMoved: (event: ColumnMovedEvent<Session>) => this.onColumnMoved(event),
        onColumnVisible: (event: ColumnVisibleEvent<Session>) => this.onColumnVisible(event),
        getMainMenuItems: (params: GetMainMenuItemsParams) => this.getMainMenuItems(params),
        defaultColDef: this.defaultColDef,
        columnDefs: [
            {
                headerName: 'ID',
                field: 'id',
                sortable: false,
            },
            {
                headerName: 'Enabled',
                field: 'enabled',
                maxWidth: 200,
                minWidth: 200,
                //sortable: true,
                //comparator: () => 0,
                cellRenderer: (params: ICellRendererParams) => {
                    const isChecked = params.data.enabled ? 'checked' : '';
                    return `<div class="form-check form-switch">
                            <input type="checkbox" class="form-check-input medium" ${isChecked}/>
                        </div>`;
                },
                onCellClicked: (event: CellClickedEvent<Session>) => this.onCellClickEnabled(event),
            },
            {
                headerName: 'Host',
                field: 'hostIp',
                maxWidth: 250,
                minWidth: 250,
                cellRenderer: (params: ICellRendererParams<Session>) => {
                    return `<div class="one-line d-flex align-items-center gap-0_5">
                    ${params.data.hostIp}:${params.data.hostPort} <span class="fas fa-circle ${params.data.online ? 'text-sucess-s2' : 'text-error-r2'}"></span>
                    </div>`;
                },
            },
            {
                headerName: 'Login',
                field: 'systemId',
                sortable: false,
            },
            {
                headerName: 'System type',
                field: 'systemType',
                sortable: false
            },
            {
                headerName: 'Service type',
                field: 'serviceType',
                sortable: false
            },
            {
                headerName: 'User',
                field: 'userId',
                sortable: true,
                cellRenderer: (params: ICellRendererParams<Session>) => {
                    return `<div class="one-line d-flex align-items-center gap-0_5">${params.data.userId}</div>`;
                },
            },
            {
                headerName: 'Actions',
                maxWidth: 150,
                minWidth: 150,
                sortable: false,
                pinned: 'right',
                lockPinned: true,
                lockPosition: 'right',
                lockVisible: true,
                suppressColumnsToolPanel: false,
                suppressAutoSize: true,
                headerClass: 'action-cell',
                cellClass: 'action-cell',
                cellRenderer: SessionsTableActionsRendererComponent,
                cellRendererParams: {
                    onAction: (action: SessionsTableAction, params: ICellRendererParams<Session>) => this.onAction(action, params)
                },
                getQuickFilterText: params => '',
            }
        ],
        popupParent: document.body,
        suppressMenuHide: true,
        suppressDragLeaveHidesColumns: true,
        tooltipShowDelay: 300,
    };

    rows: Session[] = [];
    loading = false;
    private $update: Subscription;

    @ViewChild('sessionModalTpl', { read: TemplateRef, static: false }) sessionModalTpl: any;
    sessionModal: DialogRef;
    editModel: Session;

    params = new SessionAllRequestParams();
    @Input() searchValue = '';

    constructor(
        private userService: UserService,
        private sessionService: SessionService,
        private storage: LocalStorage,
        private datePipe: DatePipe,
        private modalService: ModalService,
        private notifications: NotificationService
    ) {
        super();
    }

    ngOnInit() {
        const userId = this.userService.user.value.id;
        this.columnChange$.pipe(debounceTime(1000)).subscribe((event: ColumnMovedEvent | ColumnResizedEvent | ColumnVisibleEvent) => {
            this.storage.setItem<ColumnState[]>(`sessions-table-columns-${userId}`, this.gridApi.getColumnState());
        });
        this.paginationPageSize = this.storage.getItem<number>(`sessions-table-size-${userId}`, 20);
    }

    private onCellClickEnabled(event: CellClickedEvent<Session>) {
        this.loading = true;
        this.sessionService.toggleEnabled([event.data.id], !event.data.enabled).pipe(
            catchError(e => {
                this.loading = false;
                return throwError(() => e);
            })
        ).subscribe(page => {
            this.update();
        });
    }

    update() {
        this.loading = true;
        this.params.search = this.searchValue;
        this.params.page = this.currentPageNumber - 1;
        this.params.size = this.paginationPageSize;
        this.$update = this.sessionService.all(this.params)
            .pipe(
                catchError(e => {
                    this.loading = false;
                    return throwError(() => e);
                })
            )
            .subscribe(page => {
                this.rows = page.content;
                this.totalRowsCount = page.totalElements;
                this.loading = false;
            });
    }

    private onAction(action: SessionsTableAction, params: ICellRendererParams<Session>) {
        if (action === 'edit') {
            this.editModel = params.data;
            this.sessionModal = this.modalService.alert().component(this.sessionModalTpl).open();
        }
        if (action === 'delete') {
            this.modalService.confirm().then(confirm => {
                if (!confirm) {return;}
                this.loading = true;
                this.sessionService.delete(params.data.id).pipe(
                    catchError(e => {
                        this.loading = false;
                        return throwError(() => e);
                    })
                ).subscribe(() => {
                    this.notifications.success('Session deleted', 'Sessions');
                    this.update();
                });
            });
        }
    }

    createSession() {
        this.editModel = this.sessionService.create();
        this.sessionModal = this.modalService.alert().component(this.sessionModalTpl).open();
    }

    onAfterSaveSession() {
        this.sessionModal.close();
        this.update();
    }

    override changeSize($event, size: number) {
        this.paginationPageSize = size;
        const userId = this.userService.user.value.id;
        this.storage.setItem<number>(`sessions-table-size-${userId}`, size);
        this.update();
    }

    ngOnDestroy() {
        if (this.$update && !this.$update.closed) {
            this.$update.unsubscribe();
        }
    }
}

export type SessionsTableAction = 'edit'|'delete';
export type SessionsTableActionFun = (action: SessionsTableAction, params: ICellRendererParams<Session>) => void;