import {Component, TemplateRef} from '@angular/core';
import { DialogRef, ModalService } from "../../services/modal.service";
import {animate, state, style, transition, trigger} from "@angular/animations";
import { NgClass, NgForOf, NgIf, NgTemplateOutlet } from "@angular/common";

@Component({
    selector: 'app-modals-widget',
    templateUrl: 'modals-widget.component.html',
    styleUrls: ['modals-widget.component.scss'],
    animations: [
        trigger('openCloseBackdrop', [
            state('open', style({opacity: 0.8})),
            state('closed', style({opacity: 0, display: 'none'})),
            transition('open => closed', [animate('0.2s')]),
            transition('closed => open', [
                style({display: 'block'}),
                animate('0.2s')
            ])
        ]),
        trigger('openCloseModal', [
            state('open', style({top: 0})),
            state('closed', style({top: '-100%'})),
            transition('open => closed', [animate('0.2s')]),
            transition('closed => open', [
                style({top: '-100%'}),
                animate('0.2s')
            ])
        ])
    ],
    imports: [
        NgForOf,
        NgClass,
        NgIf,
        NgTemplateOutlet
    ],
    standalone: true
})
export class ModalsWidgetComponent {

    dialogs: ViewDialog[] = [];

    backdropShow = false;

    constructor(public service: ModalService) {
        service.modals.subscribe(dialogConfig => {
            const isShow = this.backdropShow === false;
            const id = this.dialogs.length;
            const context = dialogConfig.getContext();
            const dialog = {
                origin: dialogConfig,
                id: id,
                className: this.getSizeClass(dialogConfig),
                title: context.title,
                body: context.body,
                component: context.component,
                isOpen: false,
                isConfirm: context.type === 'confirm',
                isTop: true
            };
            if (dialog.isConfirm) {dialog.className += ' modal-dialog-confirm';}
            this.dialogs.forEach(_ => _.isTop = false);
            this.dialogs.push(dialog);
            setTimeout(() => dialog.isOpen = true, 0);
            dialogConfig.onDestroy.subscribe(() => {
                dialog.isOpen = false;
                if (this.dialogs.length === 1) {
                    this.onBackdropHide();
                }
                setTimeout(() => { // delay animation
                    this.dialogs = this.dialogs.filter(_ => _.id !== id);
                    if (this.dialogs.length) {
                        this.dialogs[this.dialogs.length - 1].isTop = true;
                    }
                }, 100);
            });
            if (isShow) {
                this.onBackdropShow();
            }
        });
    }

    onClickBackdrop(dialog: ViewDialog) {
        if (!dialog.origin.getContext().isBlocking) {
            dialog.origin.close(false);
        }
    }

    onClickContent(ev) {
        ev.stopPropagation();
    }

    onBackdropShow() {
        document.body.classList.add('modal-open');
        this.backdropShow = true;
    }

    onBackdropHide() {
        document.body.classList.remove('modal-open');
        this.backdropShow = false;
    }

    private getSizeClass(dialog: DialogRef): string {
        const context = dialog.getContext();
        if (context.size) {
            return 'modal-dialog ' + context.size;
        }
        if (context.dialogClass) {
            return context.dialogClass;
        }
        return 'modal-dialog';
    }
}

interface ViewDialog {
    id: number,
    origin: DialogRef,
    title: string,
    body: string,
    component?: TemplateRef<any>,
    className: string,
    isOpen: boolean,
    isConfirm: boolean,
    isTop: boolean
}

