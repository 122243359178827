<div class="content-wrapper-inner relative">
    <div class="page-content">
        <div class="table-topbar mb-20">
            <div class="search-holder w-25">
                <div class="search-control">
                    <span class="icon-search"></span>
                    <input type="search" class="form-control table-search" #searchInput />
                </div>
            </div>
            <div class="table-action-holder">
                <div class="create"></div>
            </div>
            <div class="filter-right-col text-right gap-10">
                <button class="btn btn-primary" (click)="table.createSession()">Create</button>
            </div>
        </div>
        <div class="dataTables_wrapper form-inline dt-bootstrap pos-relative">
            <app-sessions-table></app-sessions-table>
        </div>
    </div>
</div>
