import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import { UserService } from "./user.service";
import { Router } from "@angular/router";
import { BrowserUtils } from "./browser-utils";
import { httpStatuses } from "../models/http-statuses";
import { NzMessageService } from "ng-zorro-antd/message";
import { AppService } from "./app.service";


@Injectable({providedIn: 'root'})
export class HttpErrorInterceptor implements HttpInterceptor {

    private services = ['number-intelligence', 'state-manager', 'billing', 'api', 'sessions-crud'];

    constructor(
        private message: NzMessageService,
        @Inject('API_URL') private baseUrl: string,
        private appService: AppService,
        private userService: UserService,
        private router: Router
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const newReq = req.clone();
        return next.handle(newReq).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status === 401) {
                    this.userService.isAuth.next(false);
                    this.router.navigate(['/login']).then();
                } else {
                    this.handleHttpError(error);
                }
                return throwError(() => error);
            })
        );
    }

    handleHttpError(response: HttpErrorResponse) {
        if (response.status === 0 && !BrowserUtils.isUnload) {
            this.message.create('info', 'Connection issue encountered. Check your internet connection');
        }
        let statusText = String(response.status) + ' ';
        if (httpStatuses.has(response.status)) {
            statusText += httpStatuses.get(response.status) as string;
        } else {
            statusText += 'Unknown error';
        }

        if (!response.error) {
            this.appService.responseErrorNotification({status: statusText, message: '', serverError: null});
            return;
        }

        if (typeof response.error === 'string') {
            this.appService.responseErrorNotification({status: statusText, message: response.error, serverError: null});
            return;
        }

        this.appService.responseErrorNotification({status: statusText, message: '', serverError: response.error});
    }

    private extractServiceFormUrl(url: string): string {
        for (let s of this.services) {
            if (url.includes(`/${s}`)) {return s;}
        }
        if (url.includes(this.baseUrl)) {
            return 'gateway';
        }
        return 'unknown';
    }
}