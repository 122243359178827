<div [@openCloseBackdrop]="backdropShow ? 'open' : 'closed'" class="modal-backdrop fade in"></div>
<div *ngFor="let dialog of dialogs" class="modal fade in" [style]="{display: 'block', position: 'fixed'}" [@openCloseModal]="dialog.isOpen ? 'open' : 'closed'" (click)="onClickBackdrop(dialog)" tabindex="-1">
    <div [ngClass]="dialog.className">
        <div (click)="onClickContent($event)" class="modal-content">
            <div [@openCloseBackdrop]="!dialog.isTop ? 'open' : 'closed'" class="modal-backdrop"></div>
            <ng-container *ngIf="dialog.component">
                <ng-container *ngTemplateOutlet="dialog.component; context: {dialogRef: dialog.origin}"></ng-container>
            </ng-container>
            <ng-container *ngIf="!dialog.component">
                <div class="modal-header mb-10">
                    <div class="modal-title">
                        <h3 class="d-inline-block">{{dialog.title}}</h3>
                        <button type="button" class="btn-close" (click)="dialog.origin.close(false)">
                            <span class="icon-close"></span>
                        </button>
                    </div>
                </div>
                <div class="modal-body mb-15" [innerHTML]="dialog.body"></div>
                <div class="modal-footer pt-15">
                    <div class="button-holder d-flex justify-content-between" *ngIf="dialog.isConfirm">
                        <button class="btn btn-outline-dark" (click)="dialog.origin.close(false)">Cancel</button>
                        <button class="btn btn-primary ms-15" (click)="dialog.origin.close(true)"> Yes</button>
                    </div>
                    <div class="button-holder d-flex justify-content-between" *ngIf="!dialog.isConfirm">
                        <button class="btn btn-primary ms-15" (click)="dialog.origin.close(true)"> Ok</button>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>