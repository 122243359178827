<div [hidden]="!loading" class="spinner"><div class="sk-spinner sk-spinner-pulse"></div></div>
<ag-grid-angular [gridOptions]="gridOptions" [rowData]="rows" [ngClass]="[theme]" style="width: 100%;height: 100%"></ag-grid-angular>
<div class="d-flex justify-content-between align-items-center my-15 has-pagination">
    <div class="pagination-left">
        <app-pagination *ngIf="totalRowsCount > paginationPageSize" [totalItems]="totalRowsCount" [currentPage]="currentPageNumber" (change)="onPageChange($event)" [pageSize]="paginationPageSize"></app-pagination>
    </div>
    <div class="d-flex align-items-center justify-content-end pagination-right">
            <span class="pagination-count">Showing {{ totalRowsCount > paginationPageSize ? paginationPageSize  : totalRowsCount }} of {{ totalRowsCount }} results</span>
        <ul *ngIf="sizes.length" class="pagination">
            <li *ngFor="let size of sizes" [ngClass]="{ active: size === paginationPageSize }">
                <a (click)="changeSize($event, size)">{{ size }}</a>
            </li>
        </ul>
    </div>
</div>
<ng-template #sessionModalTpl let-dialogRefTemplate="dialogRef">
    <div class="modal-header mb-10">
        <div class="modal-title">
            <h3 class="d-inline-block">{{ editModel.id ? 'Edit' : 'Create' }} session</h3>
            <button type="button" class="btn-close" (click)="dialogRefTemplate.close(true)">
                <span class="icon-close"></span>
            </button>
        </div>
    </div>
    <div class="modal-body" style="min-height: 150px">
        <div style="margin-bottom: 1rem">
            <app-session-form
                    [model]="editModel"
                    (onCancel)="dialogRefTemplate.close()"
                    (onAfterSave)="onAfterSaveSession()"
            ></app-session-form>
        </div>
    </div>
</ng-template>